// Germany
export const locale = {
  admin_login: 'Admin Login',
  login: 'Login',
  email: 'Email',
  password: 'Passwort',
  you_are_not_admin: 'Du bist kein Admin!',
  dashboard: 'Dashboard',
  user: 'Benutzer',
  organizer: 'Veranstalter',
  single_user: 'Einzel Nutzer',
  guest: 'Guest',
  unapproved: 'Nicht genehmigt',
  unapproved_organizer: 'Nicht genehmigter Veranstalter',
  event: 'Veranstaltung',
  post: 'Post',
  transaction: 'Transaktion',
  refund: 'Rückerstattung',
  refund_fee: 'Rückerstattungsgebühr',
  handling_fee: 'Abwicklungsgebühren',
  withdraw: 'Abhebung',
  report: 'Bericht',
  reported_user: 'Gemeldeter Benutzer',
  reported_post: 'Gemeldeter Post',
  reported_event: 'Gemeldetes Event',
  system: 'System',
  change_password: 'Passwort ändern',
  send_message_to_users: 'Sende eine Nachricht an Benutzer',
  send_email_to_users: 'Sende eine E-Mail an Benutzer',
  logout: 'Ausloggen',
  confirm_logout: 'Bist du dir sicher mit dem Ausloggen?',
  deleted: 'Gelöscht',
  sale: 'Verkauft',
  gross_sale: 'Bruttoverkauf',
  net_sale: 'Nettoverkauf',
  refunded: 'Rückerstattet',
  please_wait: 'Bitte warten ...',
  online: 'Online',
  approve: 'Genehmigt',
  decline: 'Abgelehnt',
  no_data_found: 'Keine Daten gefunden!',
  confirm_approve_organizer: 'Sind Sie sicher diesen Benutzer als Veranstalter zu genehmigen?',
  confirm_approve_single_user: 'Sind Sie sicher diesen Benutzer als Einzel Benutzer zu genehmigen?',
  success: 'Erfolgreich',
  ticket_price: 'Ticket Preis',
  promo_discount: 'Gutscheinrabatt',
  service_charge: 'Bearbeitungsgebühr',
  total_price: 'Gesamtpreis',
  discounted_: 'Mit Rabatt {0}% - {1}',
  tickets: 'Tickets',
  paid: 'Bezahlt',
  paid_by: 'Bezahlt von',
  requested: 'Angefordert',
  requested_by: 'Angefordert von',
  refund_canceled_by: 'Rückerstattung storniert von',
  total_balance: 'Kontostand',
  invoice: 'Rechnung',
  sales_amount: 'Basisumsatz',
  payout_amount: 'Auszahlungsbetrag',
  not_paid: 'Nicht Bezahlt',
  already_paid: 'Bereits Bezahlt',
  pending: 'Ausstehend',
  refund_now: 'Jetzt rückerstatten',
  cancel_refund: 'Rückerstattung abbrechen',
  remain_balance: 'Verbleibender Kontostand',
  withdraw_amount: 'Abgehobender Betrag',
  status: 'Status',
  payment_done: 'Zahlung getätigt',
  confirm_withdraw_done: 'Sind Sie sicher die Auszahlung beantragt zu haben?',
  confirm_delete_report: 'Sind Sie sicher den Bericht zu löschen?',
  current_password: 'Aktuelles Passwort',
  new_password: 'Neues Passwort',
  confirm_password: 'Passwort bestätigen',
  modify: 'Ändern',
  password_does_not_match: 'Passwort stimmt nicht überein!',
  admin_password_changed_login_again: 'Das Admin Passwort wurde erfolgreich geändert.',
  current_password_incorrect: 'Das aktuelle Passwort ist nicht richtig.',
  title: 'Titel',
  message: 'Nachricht',
  send: 'Senden',
  confirm_send_message: 'Sind Sie sicher diese Nachricht an {0} Benutzer zu schicken?',
  confirm_delete_message: 'Sind Sie sicher diese Nachricht löschen zu wollen?',
  confirm_delete_story: 'Sind Sie sicher diese Story löschen zu wollen?',
  confirm_delete_album: 'Sind Sie sicher diese Album löschen zu wollen?',
  report_type_1: 'Spam',
  report_type_2: 'Gewalt',
  report_type_3: 'Nacktheit',
  report_type_4: 'Hasspredigen',
  report_type_5: 'Terror',
  report_type_6: 'Nicht zugelassene Werbung',
  select_month: 'Wähle einen Monat',
  select_date_range: 'Datumsbereich auswählen',
  private: '(Privat)',
  confirm_delete: 'Bist du sicher das du löschen möchtest?',
  drink: 'Getränk',
  orders: 'Bestellungen',
  paid_online: 'Online bezahlt',
  paid_by_card: 'EC/Kreditkarte',
  paid_by_cash: 'Barzahlung',
  tip: 'Trinkgeld',
  request_refund: 'Rückerstattung anfordern',
  select_event: 'Event auswählen',
  select_organizer: 'Organizer auswählen',
  add_event: 'Event hinzufügen',
  clear_selection: 'Auswahl zurücksetzen',
  payout_request: 'Auszahlung anfordern',
  confirm_request_drink_payout: 'Sind Sie sicher, dass Sie jetzt die Getränke Auszahlung für alle vergangenen Veranstaltungen anfordern möchten?',
  remind: 'Erinnern',
  tag: 'Ergänzen',
  tax_code: 'Steuernummer',
  event_days: 'Tage',
  cancel_withdraw: 'Auszahlung abbrechen',
};